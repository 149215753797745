import axios from "axios";

export const getData = async (setData, URL) => {
    await axios
        .get(URL)
        .then(async (res) => {
            await setData(res.data.data[0].attributes);
        })
        .catch(async (err) => {
            console.log(err);
        });
};

export const fetchData = async (data, setData, URL) => {
    await setData({ isLoading: true, content: { ...data.content } });
    await axios
        .get(URL)
        .then(async (res) => {
            await setData({ isLoading: false, content: res.data });
        })
        .catch(async (err) => {
            console.log(err);
            await setData({ isLoading: false, content: { ...data.content } });
        });
};

export const convertDimention = (dimention, type) => {
    if (type === "meter") {
        return dimention ? dimention / 3.2808 : "";
    } else if (type === "feet") {
        return dimention ? dimention * 3.2808 : "";
    } else {
        return "";
    }
};
