import React from "react";
import { NavLink } from "react-router-dom";
import { isEmpty } from "lodash";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DropDownList from "./MobileDropDownMenu";

export default function MobileMenu({ headerData, toggleDrawer }) {
    const { menuItems } = headerData;

    return (
        <Box>
            <List
                sx={{
                    width: "100%",
                    maxWidth: 460,
                    bgcolor: "background.paper",
                    a: {
                        color: "inherit",
                        textDecoration: "inherit",
                    },
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {menuItems.map((item, i) => {
                    if (isEmpty(item.options)) {
                        return (
                            <NavLink
                                key={item.label}
                                to={`/${item.path.replace(/ /g, "")}`}
                            >
                                <ListItemButton
                                    onClick={toggleDrawer(false)}
                                    onKeyDown={toggleDrawer(false)}
                                    key={item.label}
                                    sx={{ pl: 4, pr: 4 }}
                                >
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </NavLink>
                        );
                    } else {
                        return (
                            <DropDownList
                                toggleDrawer={toggleDrawer}
                                key={item.label}
                                label={item.label}
                                options={item.options}
                            />
                        );
                    }
                })}
            </List>
        </Box>
    );
}
