import React, { useState, useEffect } from "react";
import {
    Container,
    Divider,
    Typography,
    Grid,
    Link,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import * as MUIIcons from "@mui/icons-material";
import { upperCase } from "lodash";

import LocalPhoneSharpIcon from "@mui/icons-material/LocalPhoneSharp";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import RoomSharpIcon from "@mui/icons-material/RoomSharp";
import CustomizedButtonLink from "./CustomizedButtonLink";
import { getData } from "../actions";
import { API_FOOTERS } from "../constants";

function Footer() {
    const [footerData, setFooterData] = useState([]);

    useEffect(() => {
        getData(setFooterData, API_FOOTERS);
    }, []);

    const renderIcons = () => {
        return (
            footerData.socialmedialinks &&
            footerData.socialmedialinks.map((item, index) => {
                const IconComponent = MUIIcons[item.iconName];
                return (
                    <Link
                        href={item.link}
                        color="#ffffff"
                        sx={{ pl: 1, pr: 1 }}
                        target={"_blank"}
                        key={index}
                    >
                        <IconComponent fontSize="large" />
                    </Link>
                );
            })
        );
    };

    return (
        <Box
            component="footer"
            sx={{
                pt: 10,
                pb: 6,
                backgroundColor: "#0B0C1C",
            }}
        >
            <Container maxWidth="lg" disableGutters>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Box p={"16px"}>
                            <Box pt={1}>
                                {footerData && footerData.logo && (
                                    <img
                                        src={
                                            footerData.logo.data.attributes.url
                                        }
                                        alt={
                                            footerData.logo.data.attributes
                                                .alternativeText
                                        }
                                        width={"150px"}
                                        height={"150px"}
                                    />
                                )}
                                <Typography
                                    color={"primary"}
                                    // mt={1}
                                    fontWeight={"bold"}
                                    fontSize={"18px"}
                                >
                                    {footerData.logocaption}
                                </Typography>
                            </Box>
                            <Box mt={3}>
                                <Typography mb={1} color="#ffffff" gutterBottom>
                                    Follow Us
                                </Typography>
                                {renderIcons()}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <List sx={{ pt: 0 }}>
                            <ListItem>
                                <Typography fontWeight={600} color="#ffffff">
                                    {upperCase(
                                        footerData.ourcompany &&
                                            footerData.ourcompany.heading,
                                    )}
                                </Typography>
                            </ListItem>
                            {footerData.ourcompany &&
                                footerData.ourcompany.items.map(
                                    (item, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <CustomizedButtonLink
                                                    to={"/" + item.path}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        fontWeight={500}
                                                    >
                                                        {item.label}
                                                    </Typography>
                                                </CustomizedButtonLink>
                                            </ListItem>
                                        );
                                    },
                                )}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <List sx={{ pt: 0 }}>
                            <ListItem>
                                <Typography fontWeight={600} color="#ffffff">
                                    {upperCase(
                                        footerData.otherinformation &&
                                            footerData.otherinformation.heading,
                                    )}
                                </Typography>
                            </ListItem>
                            {footerData.otherinformation &&
                                footerData.otherinformation.items.map(
                                    (item, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <CustomizedButtonLink
                                                    to={"/" + item.path}
                                                >
                                                    <Typography
                                                        color="#ffffff"
                                                        fontWeight={500}
                                                    >
                                                        {item.label}
                                                    </Typography>
                                                </CustomizedButtonLink>
                                            </ListItem>
                                        );
                                    },
                                )}
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <List sx={{ pt: 0 }}>
                            <ListItem>
                                <Typography fontWeight={600} color="#ffffff">
                                    {upperCase(
                                        footerData &&
                                            footerData.contactusheading,
                                    )}
                                </Typography>
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemIcon>
                                    <LocalPhoneSharpIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <a
                                            href={
                                                "tel:+91" +
                                                footerData.contactnumber
                                            }
                                            style={{
                                                textDecoration: "none",
                                                color: "inherit",
                                            }}
                                        >
                                            <Typography color="#ffffff">
                                                {"+91 " +
                                                    footerData.contactnumber}
                                            </Typography>
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemIcon>
                                    <MailOutlineSharpIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography color="#ffffff">
                                            {footerData.email}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem alignItems="flex-start">
                                <ListItemIcon>
                                    <RoomSharpIcon
                                        fontSize="large"
                                        color="primary"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography color="#ffffff">
                                            {footerData.address}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
                <Box mt={6}>
                    <Divider
                        sx={{ borderColor: "rgb(255 255 255 / 25%)", mb: 6 }}
                    />
                    <Typography
                        color="rgb(255 149 125 / 40%)"
                        align="center"
                        fontWeight={500}
                    >
                        {"Copyright © "}
                        {new Date().getFullYear()}{" "}
                        <Link
                            sx={{ textDecoration: "inherit", color: "inherit" }}
                            color="#ffffff"
                            href="https://alphasand.in/"
                        >
                            {footerData.copyright}
                        </Link>{" "}
                        {"."}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;
