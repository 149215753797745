import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Box, Button, Link } from "@mui/material";
import CallSharpIcon from "@mui/icons-material/CallSharp";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { isEmpty } from "lodash";
import { getData } from "../../actions";

import MobileDrawerMenu from "./mobileDrawer/MobileDrawerMenu";
import { API_HEADERS } from "../../constants";
import NavBar from "./NavBar";

const Navbar = ({ setOpen }) => {
    const [headerData, setHeaderData] = useState([]);
    const [toggle, setToggle] = React.useState(false);

    const openDrawer = () => {
        setToggle(!toggle);
    };
    useEffect(() => {
        getData(setHeaderData, API_HEADERS);
    }, []);

    return (
        <React.Fragment>
            <AppBar
                position="fixed"
                color="navBar"
                elevation={0}
                sx={{
                    boxShadow:
                        "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem",
                }}
            >
                <MobileDrawerMenu
                    toggle={toggle}
                    setToggle={setToggle}
                    headerData={headerData}
                />
                <Toolbar
                    disableGutters
                    sx={{
                        paddingLeft: {
                            xs: "16px",
                            sm: "16px",
                            md: "64px",
                            lg: "64px",
                            xl: "64px",
                        },
                        paddingRight: {
                            xs: "16px",
                            sm: "16px",
                            md: "64px",
                            lg: "64px",
                            xl: "64px",
                        },
                    }}
                >
                    <Box>
                        <NavLink to="/">
                            {headerData && headerData.logo && (
                                <img
                                    src={headerData.logo.data.attributes.url}
                                    alt={
                                        headerData.logo.data.attributes
                                            .alternativeText
                                    }
                                    width={90}
                                />
                            )}
                        </NavLink>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>

                    {!isEmpty(headerData) ? (
                        <NavBar headerData={headerData} />
                    ) : null}

                    <Box display={"flex"} alignItems={"center"}>
                        <Box>
                            <Button
                                onClick={() => {
                                    setOpen(true);
                                }}
                                variant="contained"
                                size="large"
                            >
                                Let's Talk
                            </Button>
                        </Box>
                        <Box
                            ml={2}
                            pl={2}
                            sx={{
                                borderLeft: "2px solid #f1f1f1",
                            }}
                        >
                            <a
                                href={"tel:+91" + headerData.contactnumber}
                                style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                }}
                            >
                                <Box
                                    sx={{ ":hover": { color: "#F96302" } }}
                                    display={"flex"}
                                    alignItems={"center"}
                                >
                                    <Box
                                        sx={{
                                            background: "#F96302",
                                            mr: 1,
                                            p: 1,
                                            borderRadius: "50%",
                                        }}
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <CallSharpIcon sx={{ color: "#fff" }} />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: "none",
                                                sm: "block",
                                                md: "block",
                                            },
                                        }}
                                    >
                                        <b>{headerData.contactnumber}</b>
                                    </Box>
                                </Box>
                            </a>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: "block", md: "none" } }}>
                        <IconButton aria-label="delete" onClick={openDrawer}>
                            <MenuIcon color="primary" fontSize="large" />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

export default Navbar;
