import React from 'react';
import { isEmpty } from 'lodash';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MobileMenu from './MobileMenu';

export default function MobileDrawerMenu({ toggle, setToggle, headerData })
{
    const toggleDrawer = (open) => (event) =>
    {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        )
        {
            return;
        }
        setToggle(open);
    };


    return (
        <div>
            <React.Fragment>
                <SwipeableDrawer
                    anchor={"right"}
                    open={toggle}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >

                    {
                        isEmpty(headerData) ? null : <MobileMenu headerData={headerData} toggleDrawer={toggleDrawer} />

                    }


                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}