import React, { useState } from "react";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import DropDown from "./DropDown";
import CustomizedButtonLink from "../CustomizedButtonLink";

const NavBar = ({ headerData }) => {
    const { menuItems } = headerData;

    return (
        <Box
            // display={"flex"}
            pr={2}
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
        >
            {menuItems.map((item, i) => {
                if (isEmpty(item.options)) {
                    return (
                        <Box key={i} position={"relative"}>
                            <CustomizedButtonLink
                                to={"/" + item.path}
                                activeStyle={{ color: "#F96302" }}
                            >
                                <Box
                                    p="6px 8px"
                                    sx={{
                                        ":hover": { color: "#F96302" },
                                    }}
                                >
                                    {item.label}
                                </Box>
                            </CustomizedButtonLink>
                        </Box>
                    );
                } else {
                    return <DropDown key={i} item={item} />;
                }
            })}
        </Box>
    );
};

export default NavBar;
