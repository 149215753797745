import React, { useState } from "react";
import {
    Box,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Snackbar,
    Alert,
    CircularProgress,
    FormHelperText,
} from "@mui/material";
import axios from "axios";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import TextField from "@mui/material/TextField";

export default function ConsultationForm() {
    const [formData, setFormData] = useState({
        name: "",
        phone_number: "",
        email: "",
        city: "",
        product: "",
        enquiry: "",
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Name is required";
        }
        if (!formData.phone_number) {
            newErrors.phone_number = "Phone number is required";
        }
        if (!formData.email) {
            newErrors.email = "Email is required";
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            newErrors.email = "Email is invalid";
        }
        if (!formData.city) {
            newErrors.city = "City is required";
        }
        if (!formData.product) {
            newErrors.product = "Product is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (validateForm()) {
            try {
                setLoading(true);
                const formDataArray = [
                    {
                        Attribute: "FirstName",
                        Value: formData.name,
                    },
                    {
                        Attribute: "EmailAddress",
                        Value: formData.email,
                    },
                    {
                        Attribute: "Phone",
                        Value: formData.phone_number,
                    },
                    {
                        Attribute: "mx_City",
                        Value: formData.city,
                    },
                    {
                        Attribute: "mx_Products",
                        Value: formData.product,
                    },
                    {
                        Attribute: "Notes",
                        Value: formData.enquiry,
                    },
                    {
                        Attribute: "Source",
                        Value: "website",
                    },
                ];

                // console.log(formDataArray);

                const response = await axios.post(
                    "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rf57431f3208f1b5d67337c3a4a90720b&secretKey=003ee3df368fa26b641c7b8ed6acdb442de799c9",
                    formDataArray,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                console.log(
                    "Your Request Submitted Successfully:",
                    response.data
                );
                setSuccessMessage("Your Request Submitted Successfully !");
                setSnackbarOpen(true);

                setFormData({
                    name: "",
                    phone_number: "",
                    email: "",
                    city: "",
                    product: "",
                    enquiry: "",
                });

                setLoading(false);
            } catch (error) {
                console.error("Error creating lead:", error);
                setSnackbarOpen(true);
                setLoading(false);
            }
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl sx={{ mb: 4, bgcolor: "#ffffff" }} fullWidth>
                        <TextField
                            color="secondary"
                            id="outlined-basic"
                            label="Name"
                            variant="outlined"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            error={Boolean(errors.name)}
                            helperText={errors.name}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl sx={{ mb: 4, bgcolor: "#ffffff" }} fullWidth>
                        <TextField
                            color="secondary"
                            id="outlined-basic"
                            label="Phone No"
                            variant="outlined"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required
                            error={Boolean(errors.phone_number)}
                            helperText={errors.phone_number}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl sx={{ mb: 4, bgcolor: "#ffffff" }} fullWidth>
                        <TextField
                            color="secondary"
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl fullWidth sx={{ mb: 4, bgcolor: "#ffffff" }}>
                        <TextField
                            color="secondary"
                            id="outlined-basic"
                            label="City"
                            variant="outlined"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            required
                            error={Boolean(errors.city)}
                            helperText={errors.city}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormControl sx={{ mb: 4, bgcolor: "#ffffff" }} fullWidth>
                        <InputLabel
                            color="secondary"
                            id="demo-simple-select-label-enq"
                        >
                            Product you are looking for
                        </InputLabel>
                        <Select
                            color="secondary"
                            labelId="demo-simple-select-label-enq"
                            id="demo-simple-select-enq"
                            value={formData.product}
                            name="product"
                            onChange={handleChange}
                            label="Product you are looking for"
                            required
                            error={Boolean(errors.product)}
                        >
                            <MenuItem value={"psand"}>P-Sand</MenuItem>
                            <MenuItem value={"csand"}>C-Sand</MenuItem>
                            <MenuItem value={"blocks"}>
                                Concrete Blocks
                            </MenuItem>
                            <MenuItem value="aggregates">Aggregates</MenuItem>
                        </Select>
                        {errors.product && (
                            <FormHelperText error>
                                {errors.product}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl sx={{ mb: 4, bgcolor: "#ffffff" }} fullWidth>
                        <TextField
                            color="secondary"
                            id="outlined-basic"
                            multiline
                            label="Enquiry"
                            variant="outlined"
                            rows={3}
                            name="enquiry"
                            value={formData.enquiry}
                            onChange={handleChange}
                            error={Boolean(errors.enquiry)}
                            helperText={errors.enquiry}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box textAlign={"right"}>
                        {loading ? (
                            <CircularProgress color="primary" size={24} />
                        ) : (
                            <Button
                                disableElevation
                                variant="contained"
                                size="large"
                                color="primary"
                                onClick={handleSubmit}
                                endIcon={<ArrowRightAltIcon fontSize="large" />}
                            >
                                Submit
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000} // Duration to display the Snackbar
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: "top", // Position at the top
                    horizontal: "right", // Position at the right
                }}
                sx={{
                    mt: "60px",
                }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
