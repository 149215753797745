import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";

function DropDownList({ label, options, toggleDrawer }) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <React.Fragment>
            <ListItemButton onClick={handleClick} sx={{ pl: 4, pr: 4 }}>
                <ListItemText primary={label} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {options.map((item, i) => {
                    return (
                        <List
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                            component="div"
                            disablePadding
                            key={item.label}
                            sx={{
                                a: {
                                    color: "inherit",
                                    textDecoration: "inherit",
                                },
                            }}
                        >
                            <NavLink to={`/${item.path.replace(/ /g, "")}`}>
                                <ListItemButton sx={{ pl: 6 }}>
                                    <ListItemText
                                        primary={item.label}
                                        sx={{ color: "#F28B30" }}
                                    />
                                </ListItemButton>
                            </NavLink>
                        </List>
                    );
                })}
            </Collapse>
        </React.Fragment>
    );
}

export default DropDownList;
