const HOST_URL = "https://api.alphasand.in/api/";
export const API_BANNERS = HOST_URL + "banner-imgs?populate=*";
export const API_C_SAND = HOST_URL + "csands?populate=deep";
export const API_P_SAND = HOST_URL + "psands?populate=deep";
export const API_ABOUT_US = HOST_URL + "about-uses?populate=deep";
export const API_BLOG = HOST_URL + "articles?populate=deep";
export const API_BLOG_TAGS = HOST_URL + "tags?populate=*";
export const API_CONTACT_US = HOST_URL + "contacts?populate=*";
export const API_BLOCKS = HOST_URL + "blocks?populate=deep";
export const API_CALCULATOR = HOST_URL + "buildingcalculators?populate=deep";
export const API_HOME = HOST_URL + "homes?populate=*";
export const API_REVIEWS = HOST_URL + "reviews?populate=deep";
export const API_HEADERS = HOST_URL + "headers?populate=deep";
export const API_WHYALPHA = HOST_URL + "whyalphas?populate=deep";
export const API_TECH_PARTNERS = HOST_URL + "technologypartner?populate=*";
export const API_LANDING_PAGE_ABOUt_US =
    HOST_URL + "landingpageabout?populate=*";
export const API_LANDING_PAGE_OUR_PRODUCTS =
    HOST_URL + "landingpageproduct?populate=deep";
export const API_LANDING_PAGE_HOW_IT_MADE =
    HOST_URL + "landingpagehowitmade?populate=deep";
export const API_LANDING_PAGE_WHY_ALPHA =
    HOST_URL + "whychosealpha?populate=deep";
export const API_LANDING_PAGE_OUR_CUSTOMERS =
    HOST_URL + "ourcustomer?populate=*";
export const API_LANDING_PAGE_REVIEWS = HOST_URL + "reviews?populate=deep";
export const API_ARTICLES = HOST_URL + "articles?";
export const API_LANDING_PAGE_ARTICLES =
    API_ARTICLES + "pagination[page]=1&pagination[pageSize]=3&populate=*";

export const API_LANDING_PAGE_SEO = HOST_URL + "landingpage-seo?populate=deep";

export const API_PRIVACY_POLICY = HOST_URL + "privacypolicy?populate=*";
export const API_FAQ_DETAIL = HOST_URL + "faqdetails?populate=deep";

export const API_HOW_WE_MAKE_SANDS =
    HOST_URL + "sandmanufacturings?populate=deep";

export const API_HOW_WE_MAKE_BLOCKS =
    HOST_URL + "blockmanufacturings?populate=deep";

export const API_CAREER = HOST_URL + "careers?populate=deep";

export const API_BRICKS_CALCULATOR =
    HOST_URL + "bricks-calculators?populate=deep";

export const API_CONCRETE_BLOCKS_CALCULATOR =
    HOST_URL + "concrete-blocks-calculators?populate=deep";

export const API_FLOORING_CALCULATOR =
    HOST_URL + "flooring-calculators?populate=deep";

export const API_SOIL_EXCAVATION_CALCULATOR =
    HOST_URL + "soil-excavation-caluculators?populate=deep";

export const API_STEEL_CALCULATOR =
    HOST_URL + "steel-calculators?populate=deep";

export const API_CONCRETE_CALCULATOR =
    HOST_URL + "concrete-calculators?populate=deep";

export const API_FOOTERS = HOST_URL + "footers?populate=deep";

export const API_GALLERY = HOST_URL + "galleries?populate=deep";

export const BG_COLOR = "rgb(217 89 61 / 10%)";
export const Box_SHADOW_1 = "rgba(0, 0, 0, 0.1) 0px 10px 50px;";
export const Box_SHADOW_2 = "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px";
