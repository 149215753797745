import React from "react";
import { Dialog, Box } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
// import ConsultForm from '../ConsultForm';
import ConsultationForm from "../forms/contactForm";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ setOpen, open }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            maxWidth="xs"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            {/* <DialogTitle sx={{ padding: 4 }}> Let's Talk</DialogTitle> */}
            <DialogContent sx={{ padding: 4 }}>
                <Box pb={3} textAlign={"center"}>
                    <Typography
                        color={"primary"}
                        sx={{ fontSize: "24px", fontWeight: 600 }}
                    >
                        Let's Talk
                    </Typography>
                </Box>
                <ConsultationForm />
            </DialogContent>
        </Dialog>
    );
}
