import React, { useState, useEffect } from "react";
import { Typography, Stack, Chip, Avatar } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import _ from "lodash";
import { trim } from "lodash";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { fetchData, getData } from "../../actions";
import { API_BANNERS, API_BLOG } from "../../constants";
import { calculateReadingTime } from "../../helper";
import AvTimerIcon from "@mui/icons-material/AvTimer";
// import ConsultForm from "../ConsultForm";

function Banner() {
    let location = useLocation();

    const [data, setData] = useState([]);
    const URL = API_BANNERS;
    useEffect(() => {
        getData(setData, URL);
    }, []);

    const [blogdata, setblogData] = useState({});

    useEffect(() => {
        fetchData(blogdata, setblogData, API_BLOG);
    }, []);

    let path = trim(location.pathname, "/")
        ? trim(location.pathname, "/")
        : "home";

    const currentBlogSlug = path.split("/")[1];

    const currentBlog =
        blogdata.content &&
        blogdata.content.data &&
        blogdata.content.data.find(
            (blog) => blog.attributes.slug === currentBlogSlug
        );

    return (
        <Box
            position="relative"
            sx={{
                height: "65vh",
                backgroundImage: `url(${
                    data[path]?.data?.attributes.url ||
                    data[path.replace(/-/g, "")]?.data?.attributes.url
                })`,
                backgroundPosition: {
                    xs: "bottom",
                    lg: "center",
                    md: "center",
                    xl: "center",
                },
                backgroundSize: {
                    xs:
                        path.split("/")[0] === "blog"
                            ? "100% 100%"
                            : "100% 50%",
                    lg: "100% 100%",
                    md: "100% 100%",
                    xl: "100% 100%",
                },
                backgroundRepeat: "no-repeat",
                mt: {
                    xs: path.split("/")[0] === "blog" ? "" : "-56%",
                    lg: "65px",
                    md: "65px",
                    xl: "65px",
                },
            }}
        >
            {/* <ConsultForm /> */}
            {path.split("/")[0] === "blog" ? (
                <Box
                    position={"absolute"}
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    height={"100%"}
                    width={"100%"}
                    sx={{
                        backgroundImage:
                            "linear-gradient(to right, #c02344, #ca3740, #d3493d, #da5b3a, #e06c37, #e06c37, #e06d36, #e06d36, #da5c38, #d44b3b, #cb383e, #c22341)",
                        display: "flex",
                        flexDirection: "column", // Stack children vertically
                        justifyContent: "center", // Center content vertically
                        alignItems: "center", // Center content horizontally
                        textAlign: "center", // Center text within child Boxes
                        color: "#fff",
                    }}
                >
                    <Box
                        position={"absolute"}
                        top={"30%"}
                        textAlign={"center"}
                        display="flex"
                        justifyContent="center"
                        alignItems={"center"}
                        sx={{
                            color: "#fff",
                        }}
                    >
                        <Typography
                            variant="h1"
                            component="h1"
                            color={"#fff"}
                            // fontSize="60px"
                            sx={{
                                fontSize: {
                                    xs: "24px",
                                    lg: "60px",
                                    md: "60px",
                                },
                            }}
                        >
                            {!_.isEmpty(currentBlog)
                                ? currentBlog.attributes.title
                                : ""}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="h6"
                            fontWeight={100}
                            color={"#fff"}
                            mt="50px"
                            sx={{
                                fontSize: {
                                    xs: "16px",
                                    lg: "24px",
                                    md: "24px",
                                },
                            }}
                        >
                            {!_.isEmpty(currentBlog)
                                ? currentBlog.attributes.excerpt
                                : ""}
                        </Typography>
                        <Box
                            alignItems={"center"}
                            justifyContent={"center"}
                            display={"flex"}
                            mt={4}
                        >
                            <Stack>
                                <Chip
                                    sx={{
                                        fontWeight: 500,
                                        color: "#fff",
                                    }}
                                    avatar={
                                        <Avatar
                                            alt={
                                                !_.isEmpty(currentBlog)
                                                    ? currentBlog.attributes
                                                          .author
                                                    : ""
                                            }
                                            src={
                                                !_.isEmpty(currentBlog)
                                                    ? currentBlog.attributes
                                                          .avatar.data
                                                          .attributes.url
                                                    : ""
                                            }
                                        />
                                    }
                                    label={
                                        !_.isEmpty(currentBlog)
                                            ? currentBlog.attributes.author
                                            : ""
                                    }
                                    variant="outlined"
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                pl={4}
                            >
                                <CalendarMonthIcon />
                                <Typography variant="body2" color={"#fff"}>
                                    {!_.isEmpty(currentBlog)
                                        ? currentBlog.attributes.published
                                        : ""}
                                </Typography>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                pl={4}
                            >
                                <AvTimerIcon color={"#fff"} />
                                <Typography variant="body2" color={"#fff"}>
                                    {calculateReadingTime(
                                        !_.isEmpty(currentBlog)
                                            ? currentBlog.attributes.content
                                            : ""
                                    )}
                                    mins
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    position={"absolute"}
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    height={"100%"}
                    width={"100%"}
                    // sx={{ backgroundColor: "rgb(0 0 0 / 50%)" }}
                ></Box>
            )}
        </Box>
    );
}
export default Banner;
