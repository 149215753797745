import React, { useState, useEffect, lazy, Suspense } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useLocation,
} from "react-router-dom";
import { isEmpty } from "lodash";
import { Box } from "@mui/material";
import Banner from "./components/banner";
import AlertDialogSlide from "./components/dialog";
import Navbar from "./components/header/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./ScrollTop";

const Home = lazy(() => import("./pages/Home"));
const Reviews = lazy(() => import("./pages/Reviews"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const Contact = lazy(() => import("./pages/Contact"));
const Psand = lazy(() => import("./pages/Psand"));
const Csand = lazy(() => import("./pages/Csand"));
const Blocks = lazy(() => import("./pages/Blocks"));
const BuildingCalculator = lazy(() => import("./pages/BuildingCalculator"));
const WhyAlpha = lazy(() => import("./pages/WhyAlpha"));
const Blog = lazy(() => import("./pages/Blog"));
const BlogDetail = lazy(() => import("./pages/BlogDetail"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const HowWeMakeSands = lazy(() => import("./pages/HowWeMakeSands"));
const HowWeMakeBlock = lazy(() => import("./pages/HowWeMakeBlock"));
const FAQ = lazy(() => import("./pages/FAQ"));
const Career = lazy(() => import("./pages/Career"));
const Calculator = lazy(() => import("./pages/Calculator"));
const Sitemaps = lazy(() => import("./pages/Sitemaps"));
const Gallery = lazy(() => import("./pages/Gallery"));

function App(props) {
    const [open, setOpen] = useState(false);

    return (
        <Box>
            <Router>
                <AlertDialogSlide setOpen={setOpen} open={open} />
                <Navbar setOpen={setOpen} />
                <Banner />
                <Routes />
                <Footer />
                <ScrollToTop />
            </Router>
        </Box>
    );
}

function Routes() {
    const location = useLocation();

    // useEffect(() => {
    //     const segments = location.pathname.split("/");
    //     const pageTitle = segments[segments.length - 1];
    //     const formattedTitle =
    //         pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);

    //     // Update the og:title meta tag
    //     const ogTitleMetaTag = document.querySelector(
    //         'meta[property="og:title"]'
    //     );
    //     if (ogTitleMetaTag) {
    //         ogTitleMetaTag.content = !_.isEmpty(formattedTitle)
    //             ? formattedTitle.replace(/-/g, " ") +
    //               " | " +
    //               "M Sand, C Sand, & P Sand Manufacturers and Suppliers in Bangalore"
    //             : "M Sand, C Sand, & P Sand Manufacturers and Suppliers in Bangalore";
    //     }
    //     document.title = !_.isEmpty(formattedTitle)
    //         ? formattedTitle.replace(/-/g, " ") +
    //           " | " +
    //           "M Sand, C Sand, & P Sand Manufacturers and Suppliers in Bangalore"
    //         : "M Sand, C Sand, & P Sand Manufacturers and Suppliers in Bangalore";
    // }, [location]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/reviews" component={Reviews} />
                <Route path="/about-us" component={About} />
                <Route path="/services" component={Services} />
                <Route path="/contact-us" component={Contact} />
                <Route path="/p-sand" component={Psand} />
                <Route path="/c-sand" component={Csand} />
                <Route path="/concrete-blocks" component={Blocks} />
                <Route path="/gallery" component={Gallery} />
                <Route
                    path="/building-construction-cost-calculator"
                    component={BuildingCalculator}
                />
                <Route path="/why-choose-alpha" component={WhyAlpha} />
                <Route path="/blogs" component={Blog} />
                <Route path="/blog/:slug" component={BlogDetail} />
                <Route
                    path="/terms-and-conditions"
                    component={TermsAndConditions}
                />
                <Route path="/privacy" component={PrivacyPolicy} />
                <Route path="/faq" component={FAQ} />
                <Route path="/how-we-make-sand" component={HowWeMakeSands} />
                <Route
                    path="/how-we-make-cement-blocks"
                    component={HowWeMakeBlock}
                />
                <Route path="/careers" component={Career} />
                <Route path="/sitemap" component={Sitemaps} />
                <Route path="/:type" component={Calculator} />
            </Switch>
        </Suspense>
    );
}

export default App;
