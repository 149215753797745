import { createTheme } from '@mui/material/styles';
// import darkScrollbar from "@mui/material/darkScrollbar";

const theme = createTheme({

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // ...darkScrollbar(),
          backgroundColor: "#ffffff",

        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true, // No more ripple, on the whole application 💣!
      },
      styleOverrides: {
        root: {
          borderRadius: 0

        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#0B0C1C"
        },
      },
    }
  },
  palette: {
    primary: {
      // main: '#D9593D', // Replace with your desired primary color
      main: '#F96302',
      bar: "#575556"
    },
    secondary: {
      main: '#575556', // Replace with your desired secondary color
    },
    navBar: {
      main: '#FFFFFF',
    },
    // You can add more palette options like error, warning, etc.
  },
  typography: {
    fontFamily: 'Roboto', // Replace with your desired font family
    fontSize: 14, // Replace with your desired base font size

  },

  // You can customize more options like breakpoints, spacing, etc.
});

export default theme;