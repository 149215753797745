import React, { useState } from "react";
import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import CustomizedButtonLink from "../CustomizedButtonLink";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";
const DropDown = ({ item }) => {
    const [hover, setHover] = useState(false);
    return (
        <Box
            position={"relative"}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Box
                p="6px 8px"
                sx={{ color: "#0B0C1C", cursor: "pointer" }}
                color={"primary"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                {item.label}
                {hover ? <ExpandLessSharpIcon /> : <ExpandMoreSharpIcon />}
            </Box>
            {hover ? (
                <Box>
                    <Box
                        position={"absolute"}
                        sx={{ width: "100%" }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Box
                            bgcolor="#fff"
                            className="customDropDown"
                            sx={{
                                position: "relative",
                                pt: "12px",
                                pb: "12px",
                            }}
                        >
                            {item.options.map((subMenu, k) => {
                                return (
                                    <CustomizedButtonLink
                                        key={k}
                                        to={"/" + subMenu.path}
                                        activeStyle={{ color: "#F96302" }}
                                    >
                                        <Box
                                            onClick={() => setHover(false)}
                                            whiteSpace={"nowrap"}
                                            color={"primary"}
                                            sx={{
                                                padding: "12px 24px",

                                                ":hover": { color: "#F96302" },
                                            }}
                                        >
                                            {subMenu.label}
                                        </Box>
                                    </CustomizedButtonLink>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            ) : null}
        </Box>
    );
};

export default DropDown;
